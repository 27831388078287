import data from './data.json';

let { url } = data;
let { realm } = data;
let { clientId } = data;
let { authority } = data;

if (process.env.NODE_ENV !== 'development') {
  url = `${window._env_.KEYCLOAK_URL}`;
  realm = window._env_.KEYCLOAK_REALM;
  clientId = window._env_.KEYCLOAK_CLIENT_ID;
  authority = `${window._env_.KEYCLOAK_URL}/auth/realms/${realm}`;
}

export interface INameAuthProps {
  url: string;
  realm: string;
  clientId: string;
  AUTH_KEY: string;
  authority: string;
}

export const authConfig: INameAuthProps = {
  url: `${url}/auth`,
  realm,
  clientId,
  authority,
  AUTH_KEY: data.AUTH_KEY,
};
