import { MemoryEnum, ResourcesDto } from '../../data';
import {
  cpuLimit,
  cpuRequested,
  cpuWalletCnsLimit,
  cpuWalletCnsRequested,
  memoryDefault,
  memoryLimit,
  memoryWalletCnsLimit,
  memoryWalletCnsRequested,
  UnitAdapter,
} from './data';

export type ResourcesType = Omit<ResourcesDto, 'memoryLimit' | 'memoryRequested'> & {
  memoryLimit: UnitAdapter;
  memoryRequested: UnitAdapter;
};

export const initResources: ResourcesType = {
  cpuRequested,
  cpuLimit,
  memoryLimit: {
    value: memoryLimit,
    unit: MemoryEnum.Gi,
  },
  memoryRequested: { value: memoryDefault, unit: MemoryEnum.Gi },
  replicas: 1,
  imagePullSecret: '',
};

export const initWalletCNSResources: ResourcesType = {
  cpuRequested: cpuWalletCnsRequested,
  cpuLimit: cpuWalletCnsLimit,
  memoryLimit: {
    value: memoryWalletCnsLimit,
    unit: MemoryEnum.Mi,
  },
  memoryRequested: { value: memoryWalletCnsRequested, unit: MemoryEnum.Mi },
  replicas: 1,
  imagePullSecret: '',
};
