import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from 'react-oidc-context';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Toast } from 'app/components/core';
import { onSigninCallback, userManager } from 'data';
import { BreadcrumbsProvider } from 'ui';
// TODO: decide if needed
import { Buffer } from 'buffer';
import App from './pages';
import PrivateRoute from './utils/private-route';

import 'app/styles/global.module.scss';

window.Buffer = Buffer;

const container = document.getElementById('root') as HTMLElement;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      // default value
      retryDelay: 3 * 1000,
    },
  },
});

export const Canton = () => (
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <BreadcrumbsProvider>
        <AuthProvider userManager={userManager} onSigninCallback={onSigninCallback}>
          <PrivateRoute>
            <App />
            <Toast />
          </PrivateRoute>
        </AuthProvider>
      </BreadcrumbsProvider>
    </BrowserRouter>
    {import.meta.env.VITE_MODE === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
  </QueryClientProvider>
);

const root = createRoot(container);

root.render(<Canton />);
