// custom settings that work with our ouwn OAuth server
import { authConfig as auth } from '../../auth/keycloak/config';

export const authConfig = {
  response_type: 'code',
  scope: 'openid profile email',
  authority: auth.authority,
  client_id: auth.clientId,
  redirect_uri: `${window.location.origin}/domains`,
  post_logout_redirect_uri: window.location.origin,
  // userStore: new WebStorageStateStore({ store: window.sessionStorage }),
  monitorSession: true, // this allows cross tab login/logout detection
};

export const onSigninCallback = () => {
  window.history.replaceState({}, document.title, window.location.pathname);
};
