import { toast, ToastOptions } from 'react-toastify';
import { ReactNode, useCallback } from 'react';

const baseOptions: ToastOptions = {
  position: 'bottom-right',
  draggable: true,
  closeOnClick: false,
  autoClose: 4000,
  pauseOnHover: true,
  pauseOnFocusLoss: true,
  theme: 'light',
};

export const useNotifications = () => {
  const showSuccessMessage = useCallback((message: string | ReactNode, options?: ToastOptions, onOpen?: () => void) => {
    const opts = {
      ...baseOptions,
      ...options,
      autoClose: options?.delay ? 7000 : baseOptions.autoClose,
      onOpen,
    };
    toast.success(message, opts);
  }, []);

  const showErrorMessage = useCallback((message: string | ReactNode, options?: ToastOptions, onOpen?: () => void) => {
    toast.error(message, { ...baseOptions, ...options, onOpen });
  }, []);

  const showWarningMessage = useCallback(
    (message: string | ReactNode, options?: ToastOptions, onOpen?: () => void): void => {
      toast.warning(message, { ...baseOptions, ...options, onOpen });
    },
    [],
  );

  const showInfoMessage = useCallback(
    (message: string | ReactNode, options?: ToastOptions, onOpen?: () => void): void => {
      const opts = {
        ...baseOptions,
        ...options,
        autoClose: options?.delay ? 7000 : baseOptions.autoClose,
        onOpen,
      };
      toast.info(message, opts);
    },
    [],
  );

  const dismiss = useCallback((): void => {
    toast.dismiss();
  }, []);

  return { dismiss, showSuccessMessage, showErrorMessage, showWarningMessage, showInfoMessage };
};
