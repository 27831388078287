import { useAuth, withAuthenticationRequired } from 'react-oidc-context';
import { Loader } from '@intellecteu/common-ui';
import { useNotifications } from './hooks';

const PrivateRoute = ({ children }: any) => {
  const auth = useAuth();
  const { showErrorMessage } = useNotifications();
  // TBD
  // eslint-disable-next-line no-nested-ternary
  return auth.error ? (
    <>
      <h1>Error</h1>
      {showErrorMessage(auth.error?.message)}
    </>
  ) : // eslint-disable-next-line no-nested-ternary
  auth.isLoading ? (
    <Loader.FullPage />
  ) : auth.isAuthenticated ? (
    children
  ) : (
    <>{showErrorMessage('Unable to sign in')}</>
  );
};

export default withAuthenticationRequired(PrivateRoute, {
  OnRedirecting: () => {
    return <div>Redirecting...</div>;
  },
});
